@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 100;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 200;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 300;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 400;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Bold");
	font-weight: bold;
}
@font-face {
	font-family: "Helvetica Neue";
	src: local("Helvetica Neue Regular");
	font-weight: 100;
}
@font-face {
	font-family: "Helvetica Neue";
	src: local("Helvetica Neue Regular");
	font-weight: 200;
}

@custom-media --sp only screen and (width < 768px);
@custom-media --pc only screen and (width >= 768px);

$background-color: #ffffff;
$text-color: #000000;
$link-color: #000000;
$border-color: #cccccc;
$check-color: #999999;

$content-width: 960px;
