/*----------------------------------------------------------------
 * Common
 */

html {
	overflow: scroll;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	-webkit-overflow-scrolling : touch;
	text-size-adjust: 100%;

	@media --sp {
		font-size: calc(100vw / 375);
	}
}

body {
	position: relative;
	overflow: hidden;
}

a {
	text-decoration: none;
}

@media --pc {
	.sp {
		display: none !important;
	}
}

@media --sp {
	.pc {
		display: none !important;
	}
}

/*----------------------------------------------------------------
 * header
 */
body {
	position: relative;
}

.slide {
	position: relative;
	z-index: 1;

	figure {
		position: relative;
	}

	img {
		display: block;
		width: calc(var(--vwi, 1vw) * 100);
		height: calc(var(--vhi, 1vh) * 100);
		object-fit: cover;
	}

	figcaption {
		position: absolute;
	}

	@media --pc {
		figcaption {
			right: 105px;
			top: calc((var(--vhi, 1vh) * 100 - 294px) * 415 / 786);

			svg {
				width: 68px;
				height: 294px;
			}
		}
	}

	@media --sp {
		figcaption {
			right: 20rem;
			bottom: 20rem;

			svg {
				width: 68rem;
				height: 294rem;
				width: 51rem;
				height: 220rem;
			}
		}
	}
}

header {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		--fill: transparent;
	}

	@media --pc {
		gap: 60px;

		h1 {
			svg {
				width: 227px;
				height: 101px;
			}
		}

		a {
			svg {
				width: 162px;
				height: 40px;
			}

			&:hover {
				--fill: white;
			}
		}
	}

	@media --sp {
		gap: 60rem;

		h1 {
			svg {
				width: 227rem;
				height: 101rem;
			}
		}

		a {
			svg {
				width: 162rem;
				height: 40rem;
			}
		}
	}
}

.contact {
	position: absolute;
	top: 50%;
	z-index: 1;
	transform: translateY(-50%);

	@media --pc {
		left: 43px;

		a {
			svg {
				width: 10px;
				height: 111px;
			}
		}
	}

	@media --sp {
		left: 20rem;

		a {
			svg {
				width: 10rem;
				height: 111rem;
			}
		}
	}
}

address {
	position: absolute;
	z-index: 1;

	@media --pc {
		left: 45px;
		top: 55px;

		svg {
			width: 161px;
			height: 96px;
		}
	}

	@media --sp {
		left: 20rem;
		top: 20rem;

		svg {
			width: 161rem;
			height: 96rem;
		}
	}
}