* {
	&,
	&::before,
	&::after {
		font-size: 100%;
		font-weight: inherit;
		font-style: inherit;
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		vertical-align: baseline;
		background: transparent no-repeat;
	}
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	font-weight: normal;
	font-style: normal;
	line-height: 1;
}

main {
	display: block;
}

a {
	-webkit-text-decoration-skip: objects;
}

ul, ol {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 0;
	border: 0;
	border-top: 1px solid #cccccc;
}

input, select {
	vertical-align: middle;
}

img,
picture,
video {
	vertical-align: top;
}
