@charset "utf-8";

// @import "includes/mixins";
@import "includes/reset";
@import "includes/settings";
// @import "includes/easing";

@import '~tiny-slider/src/tiny-slider';

@import "common";
